import React from 'react';
import imgDatos from './assets/imgDatos';
import img30 from './assets/img30';
import img31 from './assets/img31';
import img40 from './assets/img40';
import img41 from './assets/img41';
import img50 from './assets/img50';
import img51 from './assets/img51';
import img60 from './assets/img60';
import img61 from './assets/img61';
import img70 from './assets/img70';
import img71 from './assets/img71';
import img80 from './assets/img80';
import img81 from './assets/img81';

const CLIDDA = () => {
    return(
        <div className="container">
            <h4>Clínica de Detección y Diagnóstico Automatizado (CLIDDA)</h4>
            <div className="row">
                <div className="col-6">
                    <div className="card border-info mb-3">
                        <h6 className="card-header">Requisitos</h6>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">CURP impresa</li>
                            <li className="list-group-item">Llenar formato de CLIDDA (Se entrega en la Sección Sindical)</li>
                        </ul>
                    </div>
                </div>
                <div className="col-6">
                    <div className="card border-info mb-3">
                        <h6 className="card-header">Resumen</h6>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item text-center">
                                {/* ------- hasta diciembre 2023------- 16 */}
                                <strong>{'Nov. 2018 - Dic. 2023 '}</strong><span className="badge badge-primary badge-pill">237</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="card border-info mb-3">
                        <h6 className="card-header">Último año (2023)</h6>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item text-center">
                                <img src={imgDatos} alt="datos"/>
                            </li>
                        </ul>
                        <h6 className="card-header">Gráficas</h6>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item text-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 col-xl-6">
                                        <img src={img30} alt="datos"/>
                                    </div>
                                    <div className="col-lg-12 col-xl-6">
                                        <img src={img31} alt="datos"/>
                                    </div>
                                </div>
                            </div>
                            </li>
                            <li className="list-group-item text-center">
                                <div className="row">
                                    <div className="col-lg-12 col-xl-6">
                                        <img src={img40} alt="datos"/>
                                    </div>
                                    <div className="col-lg-12 col-xl-6">
                                        <img src={img41} alt="datos"/>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item text-center">
                                <div className="row">
                                    <div className="col-lg-12 col-xl-6">
                                        <img src={img50} alt="datos"/>
                                    </div>
                                    <div className="col-lg-12 col-xl-6">
                                        <img src={img51} alt="datos"/>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item text-center">
                                <div className="row">
                                    <div className="col-lg-12 col-xl-6">
                                        <img src={img60} alt="datos"/>
                                    </div>
                                    <div className="col-lg-12 col-xl-6">
                                        <img src={img61} alt="datos"/>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item text-center">
                                <div className="row">
                                    <div className="col-lg-12 col-xl-6">
                                        <img src={img70} alt="datos"/>
                                    </div>
                                    <div className="col-lg-12 col-xl-6">
                                        <img src={img71} alt="datos"/>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item text-center">
                                <div className="row">
                                    <div className="col-lg-12 col-xl-6">
                                        <img src={img80} alt="datos"/>
                                    </div>
                                    <div className="col-lg-12 col-xl-6">
                                        <img src={img81} alt="datos"/>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CLIDDA;