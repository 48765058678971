import React from 'react';
import accTrabajo from './Resources/accTrabajo';

const AccidenteTrabajo = () => {
    return(
        <div className="container">
            <h4>Accidente de Trabajo</h4>
            <div className="row">
                <div className="col">
                    <div className="card border-info mb-3">
                        <h5 className="card-header">¿Qué hacer en caso de Accidente de Trabajo?</h5>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <img style={{maxWidth:"100%", maxHeight: "80%"}} src={accTrabajo} alt="Accidente de Trabajo"/>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccidenteTrabajo;