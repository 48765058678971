import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import RoomIcon from '@material-ui/icons/Room';
import TooltipComponent from '../Genericos/TooltipComponent';
import Direccion from './Direccion';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function TransitionsModal({open, handleClose}) {
  const classes = useStyles();
  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
                <div className="card border-info mb-3">
                    <h5 className="card-header">Ubicación</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><a href="https://www.google.com/maps/dir//deportivo+SNTSA/@19.2884174,-99.1886608,13z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x85ce00f8ff214eb7:0xf75ba8c3545e5b39!2m2!1d-99.1536411!2d19.2883397" className="card-link" target="_blank" rel="noopener noreferrer"><TooltipComponent text="Abrir mapa"><RoomIcon href="" style={{cursor:'pointer'}}/></TooltipComponent></a>Viaducto Tlalpan, Arenal 100, Tlalpan, 14610 Ciudad de México, CDMX</li>
                        <Direccion/>
                    </ul>
                </div>
          </div>
        </Fade>
      </Modal>
  );
}