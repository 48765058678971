import React, { useState, Fragment } from 'react';
import Collapse from '@material-ui/core/Collapse';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ExpandLess from '@material-ui/icons/ExpandLess';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const SubMenu = ({handleDrawerOpen, item}) => {

    const [openM, setOpenM] = useState(false);

    return (
        <Fragment>
            <ListItem button onClick={() => setOpenM(!openM)}>
                <ListItemText primary={<strong>{item.nombre}</strong>} className="text-menu" />
                {
                    (openM)
                    ? <ListItemIcon><ExpandLess className="btn-icon-menu"/></ListItemIcon>
                    : <ListItemIcon><ExpandMore className="btn-icon-menu"/></ListItemIcon>
                }
            </ListItem>
            <Collapse in={openM} timeout="auto" unmountOnExit>
                <List disablePadding>
                    { item.sub.filter(s => s.activo).map((subitem, subindex) => (
                        <Link to={subitem.to} key={subindex} onClick={() => handleDrawerOpen(false)} style={{textDecoration: 'none' }}>
                            <ListItem button>
                                <ListItemText primary={subitem.nombre} className="text-submenu"/>
                                <ListItemIcon><KeyboardArrowRight className="btn-icon-menu"/></ListItemIcon>
                            </ListItem>
                        </Link>
                    ))}
                 </List>
            </Collapse>
        </Fragment>
    );
}

export default SubMenu;