import React from 'react';
import Aprovechamiento from './BecaAprovechamientoEscolar.js';
import Excelencia from './BecaExcelenciaAcademica.js';
import Especial from './BecaEducacionEspecial.js';
import GetAppIcon from '@material-ui/icons/GetApp';

const Becas = () => {
    return(
        <div className="container">
            <h4>Becas 2023</h4>
            <div className="card border-info mb-3">
                <h5 className="card-header">Requisitos para trámite de Becas</h5>
                <h5 className="card-header">XXVII Convocatoria Beca de Aprovechamiento Escolar <a href={Aprovechamiento} download="Beca_Aprovechamiento_Escolar_2022"><GetAppIcon/></a></h5>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">Boleta o calificación, con sello oficial, del ciclo escolar 2021-2022 (un año o equivalente).</li>
                    <li className="list-group-item"><strong>En una sola hoja,</strong> último comprobante de pago <strong>(con descuento de cuota sindical)</strong> y credencial de elector vigente por ambos lados.</li>
                    <li className="list-group-item">Si el estudiante es hija o hijo del trabajador(a), deberá presentar copia tamaño carta del acta de nacimiento del estudiante para comprobar su parentesco.</li>
                    <li className="list-group-item">Para el caso de aquellos que estén concluyendo primaria, secundaria o nivel medio-superior, presentar un documento que acredite que continuaran estudiando y boleta de calificación del ciclo 2021-2022 <strong>(un año o equivalente, por lo que el certificado no tiene validéz para este efecto.</strong></li>
                    <li className="list-group-item"><strong>NOTA: CON EL FIN DE QUE EL BENEFICIO SEA EXTENSIVOA UNA MAYOR CANTIDAD DE ESTUDIANTES, EL BECARIO O LA BECARIA, SOLO PODRÁ GOZAR DEL MONTO DE UNA BECA EN EL PRESENTE CICLO ESCOLAR</strong></li><li className="list-group-item">Fecha límite: 19 de agosto de 2022</li>
                    <li className="list-group-item">El otorgamiento de becas de aprovechamiento escolar se hará en apego a los artículos 9° y 10° del Reglamento de Becas y <strong>se dará a conocer en el mes de mayo de 2023</strong></li>
                </ul>
                <h5 className="card-header">XVI Convocatoria Beca de Excelencia Académica <a href={Excelencia} download="Beca_Excelencia_Academica_2022"><GetAppIcon/></a></h5>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">Boleta o calificación, con sello oficial, del ciclo escolar 2021-2022 (un año o equivalente).</li>
                    <li className="list-group-item"><strong>En una sola hoja,</strong> último comprobante de pago <strong>(con descuento de cuota sindical)</strong> y credencial de elector vigente por ambos lados.</li>
                    <li className="list-group-item">Si el estudiante es hija o hijo del trabajador(a), deberá presentar copia tamaño carta del acta de nacimiento del estudiante.</li>
                    <li className="list-group-item">Para el caso de aquellos que estén concluyendo licenciatura o posgrado, presentar un documento que acredite que continuaran estudiando y boleta de calificación del ciclo 2021-2022 <strong>(un año o equivalente, por lo que el certificado no tiene validéz para este efecto.</strong></li>
                    <li className="list-group-item"><strong>NOTA: CON EL FIN DE QUE EL BENEFICIO SEA EXTENSIVOA UNA MAYOR CANTIDAD DE ESTUDIANTES, EL BECARIO O LA BECARIA, SOLO PODRÁ GOZAR DEL MONTO DE UNA BECA EN EL PRESENTE CICLO ESCOLAR. NO PODRÁ ACUMULAR ESTA BECA CON LA DE APROVECHAMIENTO Y/O EDUCACIÓN ESPECIAL, TANTO EN ELLOS, COMO EN UNO DE SUS HIJOS, Y EL BECARIO O LA BECARIA, SOLO PODRÁ GOZAR DEL MONTO DE UNA BECA EN EL PRESENTE CICLO</strong></li>
                    <li className="list-group-item">Fecha límite: 19 de agosto de 2022</li>
                    <li className="list-group-item">El otorgamiento de becas de aprovechamiento escolar se hará en apego a los artículos 9° y 10° del Reglamento de Becas y <strong>se dará a conocer en el mes de noviembre de 2022</strong></li>
                    <li className="list-group-item"><strong>NOTA IMPORTANTE: EL DOCUMENTO DE CONTINUIDAD, LO DEBEN PRESENTAR TODOS LOS ESTUDIANTES DE LICENCIATURA, TSU Y POSGRADO. (PUEDE SER CONSTANCIA EMITIDA POR LA ESCUELA, TIRA DE MATERIAS, COMPROBANTE DE INSCRIPCIÓN, HORARIO DE CLASES O COMPROBANTE DE PAGO DEL SIGUIENTE CICLO ESCOLAR CON LOS DATOS DEL ESTUDIANTE)</strong></li>
                </ul>
                <h5 className="card-header">VI Convocatoria Beca de Educación Especial <a href={Especial} download="Beca_Educacion_Especial_2022"><GetAppIcon/></a></h5>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">Dictamen médico motivo de la discapacidad de la hija o hijo, expedida por una institución oficial.</li>
                    <li className="list-group-item">Constancia actualizada del servicio, expedida por la institución en la que reciba educación especial o terapia por motivo de su discapacidad.</li>
                    <li className="list-group-item"><strong>En una sola hoja,</strong> último comprobante de pago (con descuento de cuota sindical) y credencial de elector vigente por ambos lados.</li>
                    <li className="list-group-item">Copia tamaño carta del acta de nacimiento para comprobar parentesco.</li>
                    <li className="list-group-item"><strong>NOTA: SOLO SE PODRÁ ACUMULAR ESTA BECA CON LA DE APROVECHAMIENTO EN EL CASO DE QUE EL PERSONAL SE CONSTITUYA EN EL PRESENTE CICLO</strong></li>
                    <li className="list-group-item">Fecha límite: 19 de agosto de 2022</li>
                    <li className="list-group-item">El otorgamiento de becas de aprovechamiento escolar se hará en apego a los artículos 9° y 10° del Reglamento de Becas y <strong>se dará a conocer en el mes de mayo de 2023</strong></li>
                </ul>
            </div>
        </div>
    );
}

export default Becas;