import React from 'react';

const LicenciaHumanitaria = () => {
    return (
        <div className="container">
            <h4>Licencia Humanitaria</h4>
            <div className="row">
                <div className="col-6">
                    <div className="card border-info mb-3">
                        <h6 className="card-header">Requisitos</h6>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">Resumen clínico actualizado y sellado por el ISSSTE</li>
                            <li className="list-group-item">Incapacidades (Si es que se tienen)</li>
                            <li className="list-group-item">Copia del último comprobante de pago</li>
                            <li className="list-group-item">Copia de credencial de trabajador del HGM</li>
                            <li className="list-group-item">Número telefónico</li>
                            <li className="list-group-item">Fecha de ingreso</li>
                            <li className="list-group-item">Copia de identificación oficial (IFE o INE vigentes, Pasaporte o Cédula profesional con fotografía)</li>
                        </ul>
                    </div>
                </div>
                <div className="col-6">
                    <div className="card border-info mb-3">
                        <h6 className="card-header">Resumen</h6>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item text-center">
                                {/* ------- diciembre 2023-------  */}
                                <strong>{'Nov. 2018 - Dic. 2023 '}</strong><span className="badge badge-primary badge-pill">30</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LicenciaHumanitaria;