import React from 'react';

const Direccion = () => {
    return (
        <li className="list-group-item">
            <strong>Horario de atención:</strong><br/>
            <strong>Martes a sábado de 09:00 a 15:00 horas y domingos de 09:00 a 12:00 horas.</strong><br/><br/>
            <strong>Teléfonos:</strong><br/>
            <strong>5573-7071</strong>
        </li>
    );
}

export default Direccion;