import React from 'react';
import HeaderDeportivo from './HeaderDeportivo';
import Direccion from './Direccion';

const CredencialesDeportivo = () => {
    
    return(
            <div className="container">
                <HeaderDeportivo />
                <div className="card border-info mb-3">
                    <h5 className="card-header">Requisitos para trámite de credenciales del deportivo del SNTSA</h5>
                    <h5 className="card-header">Trabajador(a)</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">Original y copia del último comprobante de pago (donde aparezca la cuota sindical)</li>
                        <li className="list-group-item">Original y copia de credencial de trabajador del HGM</li>
                        <li className="list-group-item">Certificado médico</li>
                    </ul>
                    <h5 className="card-header">Esposa(o)</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">Original y copia del último comprobante de pago (donde aparezca la cuota sindical)</li>
                        <li className="list-group-item">Copia de credencial del trabajador del HGM</li>
                        <li className="list-group-item">Original y copia de acta de matrimonio</li>
                        <li className="list-group-item">Original y copia de credencial de elector vigente</li>
                        <li className="list-group-item">Certificado médico</li>
                    </ul>
                    <h5 className="card-header">Hijos (de entre 5 y 24 años)</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">Original y copia del último comprobante de pago (donde aparezca la cuota sindical)</li>
                        <li className="list-group-item">Copia de credencial del trabajador del HGM</li>
                        <li className="list-group-item">Original y copia de acta de nacimiento</li>
                        <li className="list-group-item">Si son mayores de 18 años, comprobante de estudios vigentes</li>
                        <li className="list-group-item">Certificado médico</li>
                    </ul>
                    <h5 className="card-header">Padres</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">Original y copia del último comprobante de pago (donde aparezca la cuota sindical)</li>
                        <li className="list-group-item">Copia de credencial del trabajador del HGM</li>
                        <li className="list-group-item">Original y copia de acta de nacimiento del trabajador</li>
                        <li className="list-group-item">Copia de credencial de elector del padre y/o de la madre</li>
                        <li className="list-group-item">Certificado médico</li>
                    </ul>
                    <ul className="list-group list-group-flush">
                        <Direccion/>
                    </ul>
                </div>
            </div>
    );
}

export default CredencialesDeportivo;