import React from 'react';
import HeaderDeportivo from './HeaderDeportivo';

const Palapas = () => {
    return(
        <div className="container">
            <HeaderDeportivo />
            <div className="card border-info mb-3">
                <h5 className="card-header">Requisitos para renta de palapas</h5>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">Escrito de petición dirigido al Secretario General del CES XIV del CEN del SNTSA (entregar 3 meses antes del evento)</li>
                    <li className="list-group-item">Pago de $ 500.00 (liquidar 8 días antes del evento)</li>
                    <li className="list-group-item">Lista de invitados (máximo 70 personas)</li>
                    <li className="list-group-item">Último comprobante de pago (donde aparezca la cuota sindical)</li>
                    <li className="list-group-item">Copia de credencial del trabajador del HGM</li>
                    <li className="list-group-item">Horario de 12:00 a 18:00 horas</li>
                </ul>
            </div>
        </div>
    );
}

export default Palapas;