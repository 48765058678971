import React from 'react';
import { Link } from 'react-router-dom';
import fadQueEs from './Resources/FAD';
import pago1 from './Resources/GestionPago1';

const FAD = () => {
    return(
        <div className="container">
            <h4>Fondo de Auxilio por Defunción (FAD)</h4>
            <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="card border-info mb-3">
                        <h5 className="card-header">¿Qué es el FAD?</h5>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <img style={{maxWidth:"100%", maxHeight: "80%"}} src={fadQueEs} alt="FAD" />
                            </li>
                        </ul>
                    </div>
                    <br/>
                    <div className="card border-info mb-3">
                        <h5 className="card-header">Gestión del Pago</h5>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <img style={{maxWidth:"100%", maxHeight: "80%"}} src={pago1} alt="FAD" />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="card border-info mb-3">
                        <Link to="/normatividad" style={{textDecoration: 'none'}}>
                            <h5 className="card-header">Reglamento para el Funcionamiento del FAD</h5>
                        </Link>
                    </div>
                    <div className="card border-info mb-3">
                        <h5 className="card-header">Requisitos para trámite</h5>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item"><strong>Ser trabajador sindicalizado</strong></li>
                            <li className="list-group-item">Copia de credencial de trabajador del HGM</li>
                            <li className="list-group-item">Copia de credencial de elector vigente</li>
                            <li className="list-group-item">Copia del último comprobante de pago</li>
                            <li className="list-group-item">2 beneficiarios mayores de edad para registrar</li>
                            <li className="list-group-item">Llenado de formato de FAD</li>
                        </ul>
                    </div>
                    <br/>
                    <div className="card border-info mb-3">
                        <h5 className="card-header">Requisitos para cobro</h5>
                        <div className="card-body">
                            <p className="card-text">Solicitar el pago de FAD antes de 45 días a partir de la fecha de fallecimiento.</p>
                        </div>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">Formato de registro (color amarillo, azúl o verde)</li>
                            <li className="list-group-item">Acta de defunción original</li>
                            <li className="list-group-item">2 copias del último comprobante de pago (última quincena antes del deceso)</li>
                            <li className="list-group-item">Copia de credencial de elector vigente del trabajador</li>
                            <li className="list-group-item">Solicitud de pago original con firma autógrafa del responsable de cobro</li>
                            <li className="list-group-item">2 copias credencial de elector vigente del responsable de cobro</li>
                            <li className="list-group-item">En caso de ser menor de edad, la solicitud la llenará el tutor, credencial de elector vigente y acta de nacimiento original del menor</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FAD;