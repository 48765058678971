import React, { useState, Fragment } from 'react';
import TransitionsModal from './ModalDeportivo';
import InfoIcon from '@material-ui/icons/Info';
import TooltipComponent from '../Genericos/TooltipComponent';

const HeaderDeportivo = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    return(
        <Fragment>
            <h4>Deportivo SNTSA <TooltipComponent text='Ubicación'><InfoIcon onClick={() => handleOpen(true)} style={{cursor:'pointer'}} /></TooltipComponent></h4>
            <TransitionsModal open={open} handleClose={handleClose} />
        </Fragment>
    );
}

export default HeaderDeportivo;