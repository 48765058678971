import React, { useState, Fragment } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './componentes/Header/Header';
//import comunicado from './comunicado.png';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #F1F2F3',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '80%',
    maxHeight: '100%',
    overflow: 'auto',
  },
}));

const App = () => {

    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const handleClose = () => {
      setOpen(!open);
    }
    /*<h4>Mensaje importante sobre el COVID-19</h4>
                            <h6>lunes 30 de marzo de 2020</h6>
                            <iframe
                                title="Mensaje"
                                width="100%"
                                height="200px"
                                src="https://www.youtube.com/embed/TLj9HdxoCoo"
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            >
                            </iframe>*/
    return (
      <Fragment>
        <Router>
          <Header />
          <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              disableBackdropClick={true}
              BackdropComponent={Backdrop}
              BackdropProps={{
                  timeout: 500,
              }}
          >
            <Fade in={open}>
              <div className={classes.paper}>
                <div style={{maxWidth:"100%", maxHeight: "80%"}}>
                  <button onClick={handleClose} className="btn btn-link float-right">Cerrar[x]</button>
                </div>
                {/*<h3 style={{color: 'red'}}>AVISO URGENTE</h3>
                <img style={{maxWidth:"100%", maxHeight: "80%"}} src={comunicado} alt="Aviso CESXIV" />*/}
              </div>
            </Fade>
          </Modal>
        </Router>
      </Fragment>
    );
}

export default App;
