import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import TransitionsModal from '../Modal/ModalImagen';
import "./css/Styles.css";

const SliderComponent = ({ content }) => {
    
    const [open, setOpen] = useState({ status: false, img: '', id: 0 });

    const handleOpen = (image, id) => {
        setOpen({ status: true, img: image, id });
    };

    const handleClose = () => {
        setOpen({ status: false, img: '', id: 0 });
    };

    return (
        <Fragment>
            <Carousel autoPlay infiniteLoop selectedItem={open.id} >
                {content.map((item, index) => (
                    <div key={index}>
                        <img src={item.image} alt="imagen" />
                        <p className="legend">
                            <b>
                            { item.description }
                            { (item.button === 'Descarga')
                            ?   <a href={item.to} download={item.description}><button className="slider-button">{item.button}</button></a>
                            :   (item.button === 'Ir' || item.button === 'Descarga convocatoria')
                                ?   <a href={item.to} className="slider-button" target="_blank" rel="noopener noreferrer">{item.button}</a>
                                :   (item.button === 'Ver')
                                    ?   <button className="slider-button" onClick={() => handleOpen(item.image, item.id)}>{item.button}</button>
                                    :   (item.button === 'Ver documento')
                                        ?   <button className="slider-button" onClick={() => handleOpen(item.to, item.id)}>{item.button}</button>
                                        :   (item.button === '')
                                            ?   ''
                                            :   <Link to={item.to} className="slider-button">{item.button}</Link>
                            }
                            </b>
                        </p>
                    </div>
                ))}
            </Carousel>
            <TransitionsModal open={open.status} handleClose={handleClose} img={open.img}/>
        </Fragment>
    );
}
 
export default SliderComponent;