import React, { createRef, useState } from 'react';
import Catalogo from './RamaAdministrativa.json';
import GetAppIcon from '@material-ui/icons/GetApp';

const RamaAdministrativa = () => {

    const busquedaRef = createRef('');
    const [resultadoBusqueda, setResultadoBusqueda] = useState(Catalogo);
    const busqueda = () => {
        const busqueda = Catalogo.filter(item =>
            item.nombre.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(busquedaRef.current.value.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
        );
        setResultadoBusqueda(busqueda);
    };

    return(
        <div className="container">
            <h4>Catálogo de Puestos / Rama Administrativa y Afines</h4>
            <br/>
            <div className="form-group row">
                <div className="col-2"></div>
                <label className="col-2 col-form-label">Buscar</label>
                <div className="col-6">
                    <input type="text" className="form-control" ref={busquedaRef} onChange={busqueda} />
                </div>
                <div className="col-2"></div>
            </div>
            <br/>
            <div className="row">
                <div className="col-2"></div>
                <div className="col-8">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Puesto</th>
                                <th scope="col">Descarga</th>
                            </tr>
                        </thead>
                        <tbody>                    
                        {resultadoBusqueda.map((puesto, index) => (
                            <tr key={index} className={(index%2 === 0) ? '': 'table-active'}>
                                <th>{puesto.nombre}</th>
                                <td><a href={puesto.url} target="_blank" rel="noopener noreferrer"><GetAppIcon/></a></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div className="col-2"></div>
            </div>
        </div>
    );
}

export default RamaAdministrativa;