import React, { useState, createRef } from 'react';
import Servicios from './Servicios.json';

const EvaluacionMensual = () => {

    const busquedaRef = createRef('');
    const [resultadoBusqueda, setResultadoBusqueda] = useState(Servicios);
    
    const busqueda = () => {
        const busqueda = Servicios.filter(item =>
            (item.servicio === "")
            ?   item.subdireccion.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(busquedaRef.current.value.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
            :   item.servicio.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(busquedaRef.current.value.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))

        );
        setResultadoBusqueda(busqueda);
    };

    return (
        <div className="container">
            <h4>Consulta de Dirección y Subdirección de Área por Jefatura de Departamento</h4>
            <br/>
            <div className="form-group row">
                <div className="col-2"></div>
                <label className="col-2 col-form-label">Buscar</label>
                <div className="col-6">
                    <input type="text" className="form-control" ref={busquedaRef} onChange={busqueda} />
                </div>
                <div className="col-2"></div>
            </div>
            <br/>
            <div className="row">
                <div className="col">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Jefatura de Departamento</th>
                                <th scope="col">Dirección de Área</th>
                                <th scope="col">Subdirección de Área</th>
                            </tr>
                        </thead>
                        <tbody>                    
                        {resultadoBusqueda.map((puesto, index) => (
                            <tr key={index} className={(index%2 === 0) ? '': 'table-active'}>
                                <th>{puesto.servicio}</th>
                                <th>{puesto.direccion}</th>
                                <th>{puesto.subdireccion}</th>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default EvaluacionMensual;
