import React from 'react';
import resultado1 from './resultadosImg/resultadosEscalafon1.jpg';
import resultado2 from './resultadosImg/resultadosEscalafon2.jpg';

const Escalafon = () => {

    return(
            <div className="container">
                <h4>Escalafón 2023</h4>
                <br/>
                <div className="card border-info mb-3">
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            El <strong>Comité Ejecutivo Seccional XIV</strong> hace de su conocimiento los <strong>Resultados</strong> del  
                            proceso escalafonario de fecha 24 de septiembre al 07 de octubre de 2021
                        </li>
                    </ul>
                </div>
                <br/>
                <div className="row">
                    <div className="col-2"></div>
                    <div className="col-8">
                        <img src={resultado1} style={{ maxWidth: '100%'}} alt="resultados1"/>
                    </div>
                    <div className="col-2"></div>
                </div>
                <div className="row">
                    <div className="col-2"></div>
                    <div className="col-8">
                        <img src={resultado2} style={{ maxWidth: '100%'}} alt="resultados2"/>
                    </div>
                    <div className="col-2"></div>
                </div>
            </div>
    );
}

export default Escalafon;