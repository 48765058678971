import React from 'react';
import CondicionesGenerales from './Documentos/Normatividad/CondicionesGeneralesdeTrabajo2016.pdf';
import Becas from './Documentos/Normatividad/REGLAMENTO-BECAS-DE-LA-SS.pdf';
import Asistencia from './Documentos/Normatividad/REGLAMENTO-DE-ASISTENCIA-DE-LA-SS.pdf';
import Capacitacion from './Documentos/Normatividad/REGLAMENTO-DE-CAPACITACION-DE-LA-SS.pdf';
import Escalafon from './Documentos/Normatividad/REGLAMENTO-DE-ESCALAFON-DE-LA-SS.pdf';
import Productividad from './Documentos/Normatividad/REGLAMENTO-DE-ESCALAFON-DE-LA-SS.pdf';
import SeguridadHigiene from './Documentos/Normatividad/REGLAMENTO-DE-SEGURIDAD-E-HIGIENE-DE-LA-SS.pdf';
import VestuarioEquipo from './Documentos/Normatividad/REGLAMENTO-DE-VESTUARIO-Y-EQUIPO-DE-LA-SS.pdf';
import RiesgosDerechosAdic from './Documentos/Normatividad/MANUAL-PARA-PREVENIR-RIESGOS-Y-OTORGAMIENTO-DE-DERECHOS-ADICIONALES-DE-LA-SS.pdf';
import ReglamentoEstimulos from './Documentos/Normatividad/Reglamento_para_Controlar_y_Estimular_al_Personal_de_Base_2004.pdf';
import FAD from './Documentos/Normatividad/Reglamento-FAD.pdf';
import GetAppIcon from '@material-ui/icons/GetApp';

const Normatividad = () => {
    return(
        <div className="container">
            <h4>Normatividad</h4>
            <div className="row">
                <div className="col-2"></div>
                <div className="col-8">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Condiciones Generales de Trabajo</th>
                                <th scope="col">Descarga</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="table-active">
                                <th>Condiciones Generales de Trabajo</th>
                                <td><a href={ CondicionesGenerales } download="Condiciones_Generales_de_Trabajo_2016"><GetAppIcon/></a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-2"></div>
            </div>
            <br/>
            <div className="row">
                <div className="col-2"></div>
                <div className="col-8">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Normatividad derivada de las Condiciones Generales de Trabajo</th>
                                <th scope="col">Descarga</th>
                            </tr>
                        </thead>
                        <tbody>                    
                            <tr className="table-active">
                                <th>Reglamento de Becas</th>
                                <td><a href={ Becas } download="Reglamento_de_Becas"><GetAppIcon/></a></td>
                            </tr>
                            <tr>
                                <th>Reglamento de Asistencia</th>
                                <td><a href={ Asistencia } download="Reglamento_de_Asistencia"><GetAppIcon/></a></td>
                            </tr>
                            <tr className="table-active">
                                <th>Reglamento de Capacitación</th>
                                <td><a href={ Capacitacion } download="Reglamento_de_Capacitación"><GetAppIcon/></a></td>
                            </tr>
                            <tr>
                                <th>Reglamento de Escalafón</th>
                                <td><a href={ Escalafon } download="Reglamento_de_Escalafón"><GetAppIcon/></a></td>
                            </tr>
                            <tr className="table-active">
                                <th>Reglamento de Productividad</th>
                                <td><a href={ Productividad } download="Reglamento_de_Productividad"><GetAppIcon/></a></td>
                            </tr>
                            <tr>
                                <th>Reglamento de Seguridad e Higiene</th>
                                <td><a href={ SeguridadHigiene } download="Reglamento_de_Seguridad_e_Higiene"><GetAppIcon/></a></td>
                            </tr>
                            <tr className="table-active">
                                <th>Reglamento de Vestuario y Equipo</th>
                                <td><a href={ VestuarioEquipo } download="Reglamento_de_Vestuario_y_Equipo"><GetAppIcon/></a></td>
                            </tr>
                            <tr>
                                <th>Reglamento para el Funcionamiento del Fondo de Auxilio por Defunción</th>
                                <td><a href={ FAD } download="Reglamento_FAD"><GetAppIcon/></a></td>
                            </tr>
                            <tr className="table-active">
                                <th>Reglamento para Controlar y Estimular al Personal de Base de la Secretaría de Salud, por Asistencia, Puntualidad y Permanencia en el Trabajo</th>
                                <td><a href={ ReglamentoEstimulos } download="Reglamento_para_Controlar_y_Estimular_al_Personal_de_Base_de_la_Secretaría_de_Salud_por_Asistencia_Puntualidad_y_Permanencia_en_el_Trabajo"><GetAppIcon/></a></td>
                            </tr>
                            <tr>
                                <th>Manual para Prevenir Riesgos y<br/>Otorgamiento de Derechos Adicionales</th>
                                <td><a href={ RiesgosDerechosAdic } download="Manual_para_Prevenir_Riesgos_y_Otorgamiento_de_Derechos_Adicionales"><GetAppIcon/></a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-2"></div>
            </div>
        </div>
    );
}

export default Normatividad;