import React from 'react';

const Tesis = () => {
    return (
        <div className="container">
            <h4>Ayuda de Tesis</h4>
            <div className="card border-info mb-3">
                <h5 className="card-header">Requisitos</h5>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">Documento de autorización de la impresión de la tesis con sello de la Universidad</li>
                    <li className="list-group-item">Factura original de la impresión de la tesis</li>
                    <li className="list-group-item">Copia del último comprobante de pago</li>
                    <li className="list-group-item">Copia de identificación oficial (Credencial emitida por el INE actualizada)</li>
                    <li className="list-group-item">Copia de credencial de trabajador del HGM</li>
                </ul>
            </div>
        </div>
    );
}

export default Tesis;