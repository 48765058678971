import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    color: '#ffffff',
    maxWidth: 220,
    fontSize: '12px',
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const TooltipComponent = ({ children, text }) => {
    return (
        <HtmlTooltip title={text} placement="right" arrow>
            { children }
        </HtmlTooltip>
    );
}

export default TooltipComponent;