import React from 'react';
import './styles.css';

const Titulo = () => {
    return(
        <div className="col-9 titulo">
            <div><strong>Sindicato Nacional de Trabajadores de la Secretaría de Salud</strong></div>
            <div><strong>Comité Ejecutivo Seccional XIV</strong></div>
        </div>
    );
}

export default Titulo;