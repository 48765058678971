import React, { createRef, useState } from 'react';
import PlazasDisponibles from './Plazas';
import GetAppIcon from '@material-ui/icons/GetApp';

const Escalafon = () => {

    const busquedaRef = createRef('');
    const [resultadoBusqueda, setResultadoBusqueda] = useState(PlazasDisponibles);
    const busqueda = () => {
        const busqueda = PlazasDisponibles.filter(item =>
            item.nombre.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(busquedaRef.current.value.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
        );
        setResultadoBusqueda(busqueda);
    };

    return(
        <div className="container">
            <h4>Escalafón 2024</h4>
            <br/>
            <div className="card border-info mb-3">
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        El <strong>Comité Ejecutivo Seccional XIV</strong> hace de su conocimiento las <strong>convocatorias</strong> para
                        participar en las siguientes plazas:
                    </li>
                </ul>
            </div>
            <div className="form-group row">
                <div className="col-2"></div>
                <label className="col-2 col-form-label">Buscar</label>
                <div className="col-6">
                    <input type="text" className="form-control" ref={busquedaRef} onChange={() => busqueda()} />
                </div>
                <div className="col-2"></div>
            </div>
            <br/>
            <div className="row">
                <div className="col-2"></div>
                <div className="col-8">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Puesto</th>
                                <th scope="col">Descarga</th>
                            </tr>
                        </thead>
                        <tbody>                    
                        {resultadoBusqueda.map((plaza, index) => (
                            <tr key={plaza.id} className={(index%2 === 0) ? '': 'table-active'}>
                                <th>{plaza.nombre}</th>
                                <td><a href={plaza.img} download={plaza.nombre}><GetAppIcon/></a></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div className="col-2"></div>
            </div>
        </div>
    );
}

export default Escalafon;