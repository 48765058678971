import React from 'react';
import SliderComponent from './Slider/Slider';
import content from './Slider/assets/content';

const Home = () => {

    return(
            <div className="container">
                <h4>Inicio</h4>
                <SliderComponent content={ content } />
            </div>
    );
}

export default Home;