import A4 from './convocatoriasImg/administrativoA4.js';
import A5 from './convocatoriasImg/administrativoA5.js';
import A6 from './convocatoriasImg/administrativoA6.js';
import A7 from './convocatoriasImg/administrativoA7.js';
import A8 from './convocatoriasImg/administrativoA8.js';
import Cocinero from './convocatoriasImg/cocinero.js';
import Enf_esp_a from './convocatoriasImg/enfermeraEspecialistaA.js';
import Enf_esp_b from './convocatoriasImg/enfermeraEspecialistaB.js';
import Enf_esp_c from './convocatoriasImg/enfermeraEspecialistaC.js';
import Enf_gral_tit_a from './convocatoriasImg/enfermeraGeneralTituladaA.js';
import Enf_gral_tit_b from './convocatoriasImg/enfermeraGeneralTituladaB.js';
import Enf_gral_tit_c from './convocatoriasImg/enfermeraGeneralTituladaC.js';
import Enf_jefe_servicios from './convocatoriasImg/enfermeraJefeServicios.js';
import Laboratorista_a from './convocatoriasImg/laboratoristaA.js';
import Medico_especialista_b from './convocatoriasImg/medicoEspecialistaB.js';
import Medico_especialista_c from './convocatoriasImg/medicoEspecialistaC.js';
import Medico_general_c from './convocatoriasImg/medicoGeneralC.js';
import Nutricionista from './convocatoriasImg/nutricionista.js';
import Psicologo_esp from './convocatoriasImg/psicologoEspecializado.js';
import Super_porf_ts_c from './convocatoriasImg/supervisorProfTrabajoSocialC.js';

const arrayEscalfon = [
    {
        nombre: "Apoyo Administrativo en Salud A4",
        img: A4
    },
    {
        nombre: "Apoyo Administrativo en Salud A5",
        img: A5
    },
    {
        nombre: "Apoyo Administrativo en Salud A6",
        img: A6
    },
    {
        nombre: "Apoyo Administrativo en Salud A7",
        img: A7
    },
    {
        nombre: "Apoyo Administrativo en Salud A8",
        img: A8
    },
    {
        nombre: "Cocinero en Hospital",
        img: Cocinero
    },
    {
        nombre: "Enfermera Especialista A",
        img: Enf_esp_a
    },
    {
        nombre: "Enfermera Especialista B",
        img: Enf_esp_b
    },
    {
        nombre: "Enfermera Especialista C",
        img: Enf_esp_c
    },
    {
        nombre: "Enfermera General Titulada A",
        img: Enf_gral_tit_a
    },
    {
        nombre: "Enfermera General Titulada B",
        img: Enf_gral_tit_b
    },
    {
        nombre: "Enfermera General Titulada C",
        img: Enf_gral_tit_c
    },
    {
        nombre: "Enfermera Jefe de Servicios",
        img: Enf_jefe_servicios
    },
    {
        nombre: "Laboratorista A",
        img: Laboratorista_a
    },
    {
        nombre: "Médico Especialista B",
        img: Medico_especialista_b
    },
    {
        nombre: "Médico Especialista C",
        img: Medico_especialista_c
    },
    {
        nombre: "Médico General C",
        img: Medico_general_c
    },
    {
        nombre: "Nuricionista",
        img: Nutricionista
    },
    {
        nombre: "Supervisor Profesional en Trabajo Social C",
        img: Super_porf_ts_c
    },
    {
        nombre: "Psicólogo Especializado",
        img: Psicologo_esp
    },
];

export default arrayEscalfon;
