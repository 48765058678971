import React from 'react';
import calendario from './Resourses/calendario2024';

const Prestamo = () => {
    return (
        <div className="container">
            <h4>Préstamos Personales ISSSTE</h4>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                    <div className="card border-info mb-3">
                        <h5 className="card-header">Registro</h5>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                {'Registro para participar en los Sorteos Electrónicos de Préstamos Personales 2024 en '}
                                <a href="https://siaepp.issste.gob.mx" className="card-link" target="_blank" rel="noopener noreferrer">
                                    https://siaepp.issste.gob.mx
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                    <div className="card border-info mb-3">
                        <h5 className="card-header">Oficina Virtual ISSSTE</h5>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                {'Crea tu cuenta en la Oficina Virtual del ISSSTE en '}
                                <a href="https://oficinavirtual.issste.gob.mx/" className="card-link" target="_blank" rel="noopener noreferrer">
                                    https://oficinavirtual.issste.gob.mx/
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                    <div className="card border-info mb-3">
                        <h5 className="card-header">Cita para cobro</h5>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                {'Agenda tu cita en la Unidad Admnistrativa correspondiente en '}
                                <a href="https://cita-asisssteprestaciones.issste.gob.mx/IsssteCitasAdministrativasPublico/(S(3dvyjtev4p2wy0lpdaovouij))/Comun/Inicio.aspx" className="card-link" target="_blank" rel="noopener noreferrer">
                                    https://asissste.issste.gob.mx/
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="card border-info mb-3">
                        <h5 className="card-header">Calendario de Sorteos Electrónicos de Préstamos Personales 2024</h5>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <img style={{ maxWidth:"100%", maxHeight: "80%" }} src={calendario} alt="Calendario PP 2024" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Prestamo;